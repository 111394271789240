<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    viewBox="0 0 17.484 19.982"
  >
    <path
      :style="iconStyle"
      d="M13.738,12.489A3.73,3.73,0,0,0,11.4,13.3l-4-2.5a3.768,3.768,0,0,0,0-1.627l4-2.5A3.74,3.74,0,1,0,10.08,4.56l-4,2.5a3.747,3.747,0,1,0,0,5.862l4,2.5a3.747,3.747,0,1,0,3.658-2.933Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '19.982',
    },
    width: {
      type: String,
      default: '17.484',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
